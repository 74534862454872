import React from "react";
import { RichText } from "prismic-reactjs";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";

import "./SectionVerbatim.scss";

function SectionVerbatim({ componentData }) {
  return (
    <section
      style={{ backgroundColor: componentData.primary.section_color }}
      className="section-verbatim"
    >
      <RichText render={componentData.primary.section_title} />
      <div className="section-verbatim-container">
        {componentData.fields.map((card, i) => {
          return (
            <div
              key={i}
              className={
                (componentData.primary.is_card
                  ? "section-verbatim-container-element is-card"
                  : "section-verbatim-container-element is-text") +
                " " +
                (card.image_above ? "is-above" : "")
              }
            >
              {card.rate && (
                <Box
                  className="section-verbatim-container-element-box"
                  component="fieldset"
                  mb={3}
                  borderColor="transparent"
                >
                  <Rating
                    name="read-only"
                    value={card.rate}
                    precision={0.5}
                    readOnly
                  />
                </Box>
              )}

              <div className="section-verbatim-container-element-richtext">
                <RichText render={card.text_info} />
              </div>
              <div className="section-verbatim-container-element-image">
                <img src={card.image.url.split("?")[0]} />
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export const SectionVerbatimQuery = graphql`
  fragment SectionVerbatim on PRISMIC_Dynamic_page_builderBodySection_verbatim {
    type
    fields {
      rate
      image_above
      image
      text_info
    }
    primary {
      section_color
      section_title
      is_card
    }
  }
`;

export default SectionVerbatim;
