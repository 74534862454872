import React from "react";
import { graphql } from "gatsby";
import _ from "lodash";

import SEO from "../components/SEO";

// Import des section/slices de l'apllication
import HeroA from "../sections/HeroA/HeroA";
import HeroB from "../sections/HeroB/HeroB";
import HeroC from "../sections/HeroC/HeroC";
import HeroD from "../sections/HeroD/HeroD";
import SectionVerbatim from "../sections/SectionVerbatim/SectionVerbatim";
import SectionPresentation from "../sections/SectionPresentation/SectionPresentation";
import SectionRetractable from "../sections/SectionRetractable/SectionRetractable";
import SectionClients from "../sections/SectionClients/SectionClients";
import SectionVideo from "../sections/SectionVideo/SectionVideo"

// Objet permettant de relier le nom converti au Composant React
const componentsMap = {
  HeroA: HeroA,
  HeroB: HeroB,
  HeroC: HeroC,
  HeroD: HeroD,
  SectionVerbatim: SectionVerbatim,
  SectionPresentation: SectionPresentation,
  SectionRetractable: SectionRetractable,
  SectionClients: SectionClients,
  SectionVideo: SectionVideo,
};

// Définition de la page react (format React 18|19)
function DynamicPage({ data, pageContext: { uid, locale }, location }) {
  if (!data) return null;

  // Fonction de conversion du type de slice prismic en nom de composant React
  const convertSliceToComponent = (sliceType) => {
    const componentName = _.upperFirst(_.camelCase(sliceType));
    return componentsMap[componentName] || null;
  };

  const DynamicPageData = data.prismic.dynamic_page_builder;

  return (
    <>
      <SEO
        title={DynamicPageData.meta_title}
        desc={DynamicPageData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        location={location}
        pathname={location.pathname}
      />

      {/* Boule permettant d'appeler dynamiquement les éléments présent dans la page prismic */}
      {DynamicPageData.body.map((slice, index) => {
        const Component = convertSliceToComponent(slice.type);
        if (!Component) return null;
        return <Component key={index} componentData={slice} />;
      })}
    </>
  );
}

// Query graphql récupérant uniquement les types de slices présent dans la page
export const DynamicPageQuery = graphql`
  query DynamicPageQuery($uid: String!, $locale: String!) {
    prismic {
      dynamic_page_builder(lang: $locale, uid: $uid) {
        body {
          ...HeroA
          ...HeroB
          ...HeroC
          ...HeroD
          ...SectionVerbatim
          ...SectionPresentation
          ...SectionRetractable
          ...SectionClients
          ...SectionVideo
        }
      }
    }
    site {
      ...SiteInformation
    }
  }
`;

export default DynamicPage;
