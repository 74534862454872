import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import "./HeroD.scss";

function HeroD({ componentData }) {
  if (!componentData) {
    throw new Error("Le composant n'a pas recu de données");
  }

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );
    setIsMobile(mobile);
  }, []);

  const HeroDData = componentData.primary;

  const backgroundImageUrl = HeroDData.background_image.url.split("?")[0];

  const ColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText("#000000"),
      fontWeight: 500,
      fontSize: "1.1rem",
    },
  }))(Button);

  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(4),
    },
  }));

  const classes = useStyles();

  return (
    <header
      css={{
        background: `center / cover no-repeat url("${backgroundImageUrl}")`,
      }}
      className="hero-header"
    >
      <div>
        <RichText render={HeroDData.title} />
        <RichText render={HeroDData.description} />
        <ColorButton
          variant="contained"
          size=""
          href={HeroDData.demo_btn_link.url}
          className={classes.margin + " " + "hero-header-btn"}
        >
          {HeroDData.demo_btn_name}
        </ColorButton>
      </div>
    </header>
  );
}

export const HeroDQuery = graphql`
  fragment HeroD on PRISMIC_Dynamic_page_builderBodyHero_d {
    type
    primary {
      background_image
      demo_btn_link {
        ... on PRISMIC__ExternalLink {
          url
        }
      }
      title
      demo_btn_name
      description
    }
  }
`;

export default HeroD;
