import React from "react";
import { graphql } from "gatsby";

import "./SectionClients.scss";

function SectionClients({ componentData }) {
  return <h1>Hello je suis la section SectionClients !</h1>;
}

export const SectionClientsQuery = graphql`
  fragment SectionClients on PRISMIC_Dynamic_page_builderBodySection_clients {
    type
    fields {
      logo
      link {
        ... on PRISMIC__ExternalLink {
          url
        }
      }
    }
    primary {
      title
    }
  }
`;

export default SectionClients;
