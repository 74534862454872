import React from "react";
import { RichText } from "prismic-reactjs";


import "./SectionPresentation.scss";

function SectionPresentation({ componentData }) {
  componentData.fields.forEach((element) => console.log(element));

  return (
    <section
      style={{ backgroundColor: componentData.primary.section_color }}
      className="section-presentation"
    >
      <RichText render={componentData.primary.title} />
      {componentData.fields.map((part, i) => {
        return (
          <div
            key={i}
            style={{ backgroundColor: part.background_color }}
            className={
              part.image_right
                ? "section-presentation-part"
                : "section-presentation-part is-reverse"
            }
          >
            <div className="section-presentation-part-richtext">
              <RichText render={part.description} />
            </div>
            <div className="section-presentation-part-image">
              <img src={part.image.url} />
            </div>
          </div>
        );
      })}
    </section>
  );
}

export const SectionPresentationQuery = graphql`
  fragment SectionPresentation on PRISMIC_Dynamic_page_builderBodySection_presentation {
    type
    fields {
      image_right
      image
      description
      background_color
    }
    primary {
      title
      section_color
    }
  }
`;

export default SectionPresentation;
