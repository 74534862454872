import React from "react";

import "./SectionRetractable.scss";

function SectionRetractable({ componentData }) {
  return <h1>Hello je suis la section SectionRetractable !</h1>;
}

export const SectionRetractableQuery = graphql`
  fragment SectionRetractable on PRISMIC_Dynamic_page_builderBodySection_retractable{
    type
  }
`;

export default SectionRetractable;
