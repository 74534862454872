import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player/lazy";
import { RichText } from "prismic-reactjs";

import "./SectionVideo.scss";

function SectionVideo({ componentData }) {
  if (!componentData) {
    throw new Error("Le composant n'a pas recu de données");
  }

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );
    setIsMobile(mobile);
  }, []);

  const backgroundImageUrl = componentData.primary.background_image.url.split(
    "?"
  )[0];

  return (
    <section
      css={{
        background: `center / cover no-repeat url("${backgroundImageUrl}")`,
      }}
      className="section-video"
    >
      <RichText render={componentData.primary.title} />
      <ReactPlayer
        className="section-video-player"
        controls="true"
        wrapper="div"
        muted="true"
        height={isMobile ? "225px" : "478px"}
        width={isMobile ? "100vw" : "850px"}
        url={componentData.fields[0].video.url}
      />
    </section>
  );
}

export const SectionVideoQuery = graphql`
  fragment SectionVideo on PRISMIC_Dynamic_page_builderBodySection_video {
    ... on PRISMIC_Dynamic_page_builderBodySection_video {
      type
      primary {
        background_image
        title
      }
      fields {
        video {
          ... on PRISMIC__ExternalLink {
            url
          }
        }
      }
    }
  }
`;

export default SectionVideo;
