import React from "react";

import "./HeroB.scss";

function HeroB({ componentData }) {
  return <h1>Hello je suis le Hero B !</h1>;
}

export const HeroBQuery = graphql`
  fragment HeroB on PRISMIC_Dynamic_page_builderBodyHero_b {
    type
  }
`;

export default HeroB;
