import React from "react";

import "./HeroA.scss";

function HeroA({ componentData }) {
  return <h1>Hello je suis le Hero A !</h1>;
}

export const HeroAQuery = graphql`
  fragment HeroA on PRISMIC_Dynamic_page_builderBodyHero_a {
    type
  }
`;

export default HeroA;
