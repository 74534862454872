import React from "react";

import "./HeroC.scss";

function HeroC({ componentData }) {
  return <h1>Hello je suis le Hero C !</h1>;
}

export const HeroCQuery = graphql`
  fragment HeroC on PRISMIC_Dynamic_page_builderBodyHero_c {
    type
  }
`;

export default HeroC;
